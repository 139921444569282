<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('groups_accounts.groups_accounts') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('groups_accounts.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('groups_accounts.secondary_accounts') }}</label>
                <treeselect
                    :options="accounts_list"
                    :load-options="loadOptions"
                    :value="data.secondary_accounts"
                    @input="updateValue"
                    :searchable="true"
                    :class="validation && validation.secondary_accounts ? 'is-invalid' : ''"
                    :show-count="true"
                    :multiple="true"
                    :value-consists-of="'ALL_WITH_INDETERMINATE'"
                    :no-children-text="$t('No_sub_options')"
                    :no-options-text="$t('No_options_available')"
                    :no-results-text="$t('No_results_found')"
                    :placeholder="$t('Select')">
                </treeselect>
                <span v-if="validation && validation.secondary_accounts" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.secondary_accounts[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_active" :class="validation && validation.is_active ? 'is-invalid' : ''" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
                <span v-if="validation && validation.is_active" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.is_active[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="pr-0 pl-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-groups-accounts",
  data() {
    return {
      mainRoute: 'accounting/groups_accounts',
      mainRouteDependency: 'base/dependency',

      idEdit: this.$route.params.id ? this.$route.params.id : null,

      data: {
        name: null,
        secondary_accounts: [],
        is_active: false,
      },
      isEditing: false,
      validation: null,
      accounts_list: [],

    };
  },

  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/groups-accounts');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/groups-accounts');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.secondary_accounts = response.data.data.secondary_accounts;
        this.data.is_active = response.data.data.is_active;
      });
    },
    updateValue(value) {
      this.data.secondary_accounts = value
    },
    loadOptions() {
    },
    getSecondaryAccounts() {
      ApiService.get(`${this.mainRouteDependency}/secondary-accounts`).then((response) => {
        this.accounts_list = response.data.data;
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.groups_accounts"), route: '/accounting/groups-accounts'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);
    this.getSecondaryAccounts();
    if (this.idEdit) {
      this.getData();
    }

  },
};
</script>


